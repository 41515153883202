import { lazy } from "react";
import { MemberProvider } from "../context/MemberContext";
import { LoginProvider } from "../context/LoginContext";
import { ClubProvider } from "../context/ClubContext";


const Test = lazy(() => import("../pages/Test"));
const Home = lazy(() => import("../pages/Home"));
const Login = lazy(() => import("../pages/Login"));
const ActivityList = lazy(() => import("../pages/Activity/List"));
const ActivityParticipates = lazy(
  () => import("../pages/Activity/Participates")
);
const ActivityQueue = lazy(() => import("../pages/Activity/Queue"));
const ActivityChoose = lazy(() => import("../pages/Activity/Choose"));
const ActivityScan = lazy(() => import("../pages/Activity/Scan"));
const ActivityTiming = lazy(() => import("../pages/Activity/Timing"));
const ActivityDianqie = lazy(() => import("../pages/Activity/Dianqie"));
const PersonList = lazy(() => import("../pages/Person/List"));
const PersonDetail = lazy(() => import("../pages/Person/Detail"));
const ScheduleList = lazy(() => import("../pages/Schedule/List"));
const ScheduleDetail = lazy(() => import("../pages/Schedule/Detail"));
const GoodsList = lazy(() => import("../pages/Goods/List"));
const GoodsDetail = lazy(() => import("../pages/Goods/Detail"));
const GoodsCart = lazy(() => import("../pages/Goods/Cart"));
const GoodsOrder = lazy(() => import("../pages/Goods/Order"));
const GoodsTemp = lazy(() => import("../pages/Goods/Temp"));
const GoodsTempwx = lazy(() => import("../pages/Goods/Tempwx"));
const Queue = lazy(() => import("../pages/Queue"));
const Coupon = lazy(() => import("../pages/Coupon"));
const CoreList = lazy(() => import("../pages/Core/List"));
const CoreRecord = lazy(() => import("../pages/Core/Record"));
const Tickets = lazy(() => import("../pages/Tickets"));
const OrderList = lazy(() => import("../pages/Order/List"));
const ClubJoin = lazy(() => import("../pages/Club/Join"));
const ClubTemp = lazy(() => import("../pages/Club/Temp"));
const ClubSuccess = lazy(() => import("../pages/Club/Success"));
const Error = lazy(() => import("../pages/Error"));

const routes = [
  {
    path: '/test',
    element: <Test />
  },
  // 运营页面
  {
    path: "/activity/list",
    element: (
      <MemberProvider>
        <ActivityList />
      </MemberProvider>
    ),
  },
  {
    path: "/activity/participates",
    element: (
      <MemberProvider>
        <ActivityParticipates />
      </MemberProvider>
    ),
  },
  {
    path: "/activity/queue",
    element: (
      <MemberProvider>
        <ActivityQueue />
      </MemberProvider>
    ),
  },
  {
    path: "/activity/choose",
    element: (
      <MemberProvider>
        <ActivityChoose />
      </MemberProvider>
    ),
  },
  {
    path: "/activity/scan",
    element: (
      <MemberProvider>
        <ActivityScan />
      </MemberProvider>
    ),
  },
  {
    path: "/activity/timing",
    element: (
      <MemberProvider>
        <ActivityTiming />
      </MemberProvider>
    ),
  },
  {
    path: "/activity/dianqie",
    element: (
      <MemberProvider>
        <ActivityDianqie />
      </MemberProvider>
    ),
  },

  // 用户页面
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/login",
    element: (
      <LoginProvider>
        <Login />
      </LoginProvider>
    ),
  },
  {
    path: "/person/list",
    element: <PersonList />,
  },
  {
    path: "/person/detail",
    element: <PersonDetail />,
  },
  {
    path: "/schedule/list",
    element: <ScheduleList />,
  },
  {
    path: "/schedule/detail",
    element: <ScheduleDetail />,
  },
  {
    path: "/goods/list",
    element: <GoodsList />,
  },
  {
    path: "/goods/order",
    element: <GoodsOrder />,
  },
  {
    path: "/goods/cart",
    element: <GoodsCart />,
  },
  {
    path: "/goods/detail",
    element: <GoodsDetail />,
  },
  {
    path: "/goods/temp",
    element: <GoodsTemp />,
  },
  {
    path: "/goods/tempwx",
    element: <GoodsTempwx />,
  },
  {
    path: "/order/list",
    element: <OrderList />,
  },
  {
    path: "/queue",
    element: <Queue />,
  },
  {
    path: "/coupon",
    element: <Coupon />,
  },
  {
    path: "/core/list",
    element: <CoreList />,
  },
  {
    path: "/core/record",
    element: <CoreRecord />,
  },
  {
    path: "/tickets",
    element: <Tickets />,
  },
  {
    path: "/club/join",
    element: (
      <ClubProvider>
        <ClubJoin />
      </ClubProvider>
    ),
  },
  {
    path: "/club/temp",
    element: <ClubTemp />,
  },
  {
    path: "/error",
    element: <Error />,
  },
  {
    path: "/club/success",
    element: (
      <ClubProvider>
        <ClubSuccess />
      </ClubProvider>
    ),
  },
];

export default routes;
